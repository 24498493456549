export default {
  siteTitle: 'Ma French Bank - Do you speak ado ?',
  prodUrl: 'https://do-you-speak-ado.com',
  facebook: {
    active: false,
    fbSdkLang: 'fr_FR',
    appIds: {
      staging: '750572825536535',
      prod: '174928450922669'
    }
  },
  twitter: {
    active: false
  },
  iframe: {
    active: false,
    urlToRedirTo: 'http://localhost:8080/'
  },
  optinisation: {
    active: false
  },
  idfroms: {
    untagged: 0,
    email: 1,
    facebook: 2,
    twitter: 3,
    whatsapp: 4,
    messenger: 5,
    urlcopy: 6
  },
  linkNumbers: {
    client: 1,
    facebook: 1,
    twitter: 2,
    urlcopy: 3,
    whatsapp: 4,
    messenger: 5,
    exit_url: 6
  }
}
