<template>
  <div class="site-footer bg-white">
    <div
      class="footer-container flex flex-wrap items-center justify-center m-auto flex-row"
    >
      <!-- TERMS -->
      <template v-if="$te('footer.terms')">
        <div class="cta-container">
          <a
            class="cursor-pointer footer-cta font-latoBold"
            data-cy="terms-open-cta"
            @click="updateModalConfig({ isOpen: true, modalComponent: 'TermsModal' })"
          >{{$t('footer.terms')}}</a>
          <span class="dot mx-3">&nbsp;|&nbsp;</span>
        </div>
      </template>
      <!-- CONTACT -->
      <template v-if="$te('footer.contact.button')">
        <div class="cta-container">
          <a
            class="cursor-pointer footer-cta font-latoBold"
            data-cy="contact-cta"
            :href="`mailto:${$t('footer.contact.email')}?subject=${$t('footer.contact.subject')}`"
            target="_blank"
          >{{$t('footer.contact.button')}}</a>
          <span class="dot mx-3">&nbsp;|&nbsp;</span>
        </div>
      </template>

      <!-- MENTIONS -->
      <template v-if="$te('footer.mentionsModal.button')">
        <div class="cta-container">
          <a
            data-cy="mentions-cta" 
            class="cursor-pointer footer-cta font-latoBold"
            @click="updateModalConfig({ isOpen: true, modalComponent: 'MentionsModal', modalClass: 'mentions-modal' })"
          >{{$t('footer.mentionsModal.button')}}</a>
          <span class="dot mx-3">&nbsp;|&nbsp;</span>
        </div>
      </template>
      <!-- CONTACT -->
      <template v-if="$te('footer.discover.button')">
        <div class="cta-container">
          <a
            class="cursor-pointer footer-cta font-latoBold"
            data-cy="contact-cta"
            @click="exitUrl(2)"
            target="_blank"
          >{{$t('footer.discover.button')}}</a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'SiteFooter',
  computed: {
    ...mapGetters({
      decliConfig: "config/activeDecliConfig",
      user: "user/user",
      token: "global/token",
      idu: "user/idu",
      visitId: "global/visitId",
      appConfig: "config/appConfig",
    })
  },
  methods: {
    ...mapActions(['updateModalConfig']),
    exitUrl(linknumber) {
      const iduParam = this.idu != null ? "&iIdU=" + this.idu : "";
      window.open(
        `${document.location.protocol}//${document.location.host}/api/v0/${this.appConfig.baseName.replace(/[_-]/, '_')}/v1/track/page_click?token=${this.token}${iduParam}&iPageId=${this.$route.meta.pageId}&iLinkNumber=${linknumber}&iVisitId=${this.visitId}`,
        "_blank"
      );
    }
  }
}
</script>

<style lang="scss" scoped>
.site-footer {
  width: 708px;
  @media(max-width: 720px) {
    width: 100%;
  }
}
.footer-container {
  a {
    margin-right: auto !important;
  }
  .footer-cta {
    font-size: 14px;
    color: #0D4355;
  }
  .textFooter ::v-deep {
    text-align: center;
    margin: 30px 0;
    padding: 0px 30px;
  }
  .cta-container {
    padding: 10px 0px;
  }
  @media (max-width: 460px) {
    flex-direction: column;
    width: 100%;
    .cta-container {
      padding-bottom: 10px;
      width: 100%;
      text-align: center;
      border-bottom: 1px solid black;
    }
    .dot {
      display: none;
    }
  }
}

</style>
