<template>
  <div> 
    <div class="cookieModal" v-html="$t('cookies.modal.content', {client: clientName, site: productionUrl})"></div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: 'CookiesModal',
  computed: {
    ...mapGetters({
      decliConfig: 'config/activeDecliConfig',
      appConfig: 'config/appConfig'
    }),
    clientName () {
      return this.appConfig.clientName
    },
    productionUrl () {
      return this.decliConfig.prodUrl
    }
  },
  mounted() {
    this.$emit('updateSize', 'max')
  },
}
</script>

<style lang="scss" scoped>
p {
  word-break: break-word;
}

.cookieModal /deep/ {
  p {
    margin: 10px 0;
    font-size: 14px;
  }

  a {
    color: #3498db;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  h1 {
    font-size: 36px;
  }
  
  h2 {
    font-size: 22px;
  }
}
</style>
