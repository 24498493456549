import Doorman from '@/router/doorman'

export default [
  {
    name: 'register',
    path: '',
    component: () => import('@/views/Register'),
    meta: {
      pageId: 1,
      autoPageView: true,
      requiresAuth: false,
      afterEnd: false,
      nextPage: 'quiz1'
    },
    beforeEnter (to, from, next) {
      Doorman(to, from, next)
    }
  },
  {
    name: 'quiz1',
    path: 'quiz/1',
    component: () => import('@/views/QuizStep'),
    meta: {
      pageId: 2,
      autoPageView: true,
      requiresAuth: true,
      conversionPage: true,
      afterEnd: false,
      nextPage: 'quiz2'
    },
    beforeEnter (to, from, next) {
      Doorman(to, from, next)
    }
  },
  {
    name: 'quiz2',
    path: 'quiz/2',
    component: () => import('@/views/QuizStep'),
    meta: {
      pageId: 3,
      autoPageView: true,
      requiresAuth: true,
      conversionPage: false,
      afterEnd: false,
      nextPage: 'quiz3'
    },
    beforeEnter (to, from, next) {
      Doorman(to, from, next)
    }
  },
  {
    name: 'quiz3',
    path: 'quiz/3',
    component: () => import('@/views/QuizStep'),
    meta: {
      pageId: 4,
      autoPageView: true,
      requiresAuth: true,
      conversionPage: false,
      afterEnd: false,
      nextPage: 'quiz4'
    },
    beforeEnter (to, from, next) {
      Doorman(to, from, next)
    }
  },
  {
    name: 'quiz4',
    path: 'quiz/4',
    component: () => import('@/views/QuizStep'),
    meta: {
      pageId: 5,
      autoPageView: true,
      requiresAuth: true,
      conversionPage: false,
      afterEnd: false,
      nextPage: 'quiz5'
    },
    beforeEnter (to, from, next) {
      Doorman(to, from, next)
    }
  },
  {
    name: 'quiz5',
    path: 'quiz/5',
    component: () => import('@/views/QuizStep'),
    meta: {
      pageId: 6,
      autoPageView: true,
      requiresAuth: true,
      conversionPage: false,
      afterEnd: false,
      nextPage: 'confirm'
    },
    beforeEnter (to, from, next) {
      Doorman(to, from, next)
    }
  },
  {
    name: 'confirm',
    path: 'confirmation',
    component: () => import('@/views/Diffusion'),
    meta: {
      pageId: 7,
      autoPageView: true,
      requiresAuth: true,
      afterEnd: false,
      conversionPage: true
    },
    beforeEnter (to, from, next) {
      Doorman(to, from, next)
    }
  },
  {
    name: 'end',
    path: 'fin',
    component: () => import('@/views/End'),
    meta: {
      pageId: 8,
      autoPageView: true,
      afterEnd: true,
      requiresAuth: false
    },
    beforeEnter (to, from, next) {
      Doorman(to, from, next)
    }
  }
]
