<template>
  <div class="text-center">
    <img
      class="title-img m-auto -mt-2 ml-4"
      src="../../assets/register/title_pop_18@2x.png"
      alt
      srcset
    />
    <p class="text-secondary font-latoBold mt-4" v-html="$t(`register.underage_description`)"></p>
    
    <div class="shares flex flex-wrap justify-center virals">
      <NCta
        data-cy="share-facebook-cta"
        borderRadius="30px"
        :backgroundColor="colorStyle.primary"
        share_type="facebook"
        @click.native="shareFb(); trackClick(linkNumbers.facebook)"
      >
        <template #icon>
          <NIconFacebook color="#0D4355" />
        </template>
      </NCta>

      <NCta
        data-cy="share-messenger-cta"
        share_type="messenger"
        borderRadius="30px"
        :backgroundColor="colorStyle.primary"
        v-if="typeOfDevice === 'mobile'"
        @click.native="shareMessenger(); trackClick(linkNumbers.messenger)"
      >
        <template #icon>
          <NIconMessenger color="#0D4355" />
        </template>
      </NCta>

      <NCta
        v-if="typeOfDevice === 'mobile'"
        data-cy="share-whatsapp-cta"
        borderRadius="30px"
        :backgroundColor="colorStyle.primary"
        share_type="whatsapp"
        @click.native="shareWhatsapp(); trackClick(linkNumbers.whatsapp)"
      >
        <template #icon>
          <NIconWhatsapp color="#0D4355" />
        </template>
      </NCta>

      <NCta
        @click.native="shareMail()"
        share_type="envelope"
        data-cy="share-mail-cta"
        borderRadius="30px"
        :backgroundColor="colorStyle.primary"
      >
        <template #icon>
          <NIconMail color="#0D4355" />
        </template>
      </NCta>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import design from '@/validators/design'
import { isProdEnv, getDeviceType } from "../../helpers";

// import svg share icon
import {
  NIconMail,
  NIconFacebook,
  NIconWhatsapp,
  NIconMessenger,
} from "@team-uep/n-icon";

export default {
  name: 'UnderageModal',
  props: {
    param: {
      type: String,
      default: "",
    }
  },
  data: function() {
    return {
      colorStyle: design.colors,
      panoplyStyle: design.panoply,
      typeOfDevice: getDeviceType(),
    };
  },
  components: {
    NIconMail,
    NIconFacebook,
    NIconWhatsapp,
    NIconMessenger
  },
  computed: {
    ...mapGetters({
      decliConfig: "config/activeDecliConfig",
      user: "user/user",
      token: "global/token",
      idu: "user/idu",
      visitId: "global/visitId",
      appConfig: "config/appConfig",
    }),

    idfroms() {
      return this.decliConfig.idfroms;
    },
    linkNumbers() {
      return this.decliConfig.linkNumbers;
    },
    score() {
      return this.user.oResponses.iQuestion_502
    }
  },
  methods: {
    ...mapActions(['updateModalConfig']),
    closeModal() {
      return this.updateModalConfig({ isOpen: false, modalComponent: '' })
    },
    shareMail() {
      window.open(`mailto:?to=&subject=J'ai besoin de ton aide pour relever le #WeStartChallenge 💪&body=${encodeURIComponent("Bonjour,\r\n\r\nJe t'invite à participer au #WeStartChallenge. Montre-moi que tu n'es pas trop rétro en répondant au quizz pour tenter de remporter 2 smartphones dernière génération et pleins d'autres cadeaux à partager ! \r\n\r\nPrêt(e) à relever le défi ? \r\n\r\nIl te suffit de suivre ce lien. https://do-you-speak-ado.com/?idfrom=8\r\n\r\nBonne chance, je croise les doigts pour toi !")}`, '_blank')
      // this.closeModal().then(()=> {
      //   this.updateModalConfig({ isOpen: true, modalComponent: 'DiffusionEmailsModal', modalClass: 'diffusion-email-modal' })
      // })
    },
    shareFb() {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.getShareUrl(this.idfroms.facebook))}`, '_blank')
    },
    exitUrl(linknumber) {
      const iduParam = this.idu != null ? "&iIdU=" + this.idu : "";
      window.open(
        `${document.location.protocol}//${document.location.host}/api/v0/${this.appConfig.baseName.replace(/[_-]/, '_')}/v1/track/page_click?token=${this.token}${iduParam}&iPageId=${this.$route.meta.pageId}&iLinkNumber=${linknumber}&iVisitId=${this.visitId}`,
        "_blank"
      );
    },
    shareTwitter() {
      window.open(
        `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        this.$t("diffusion.share.twitter_text")
      )}&url=${encodeURIComponent(this.getShareUrl(this.idfroms.twitter))}`,
        "_blank"
      ); 
    },
    shareWhatsapp() {
      window.location.href = `whatsapp://send?text=Prêt(e) à relever le défi ? ${encodeURIComponent(this.getShareUrl(this.idfroms.whatsapp))}`
    },
    shareMessenger() {
      window.location.href = `fb-messenger://share/?link=${encodeURIComponent(
        this.getShareUrl(this.idfroms.messenger)
      )}&app_id=${
        isProdEnv()
          ? this.decliConfig.facebook.appIds.prod
          : this.decliConfig.facebook.appIds.staging
      }, _self`;
    },
    getShareUrl(idfrom) {
      return `${window.location.origin}?idfrom=${idfrom}&idup=${this.idu}`;
    },
    trackClick(linkId) {
      const oTrackData = {
        iIdU: +this.idu,
        iPageId: this.$route.meta.pageId,
        iLinkNumber: linkId,
        iVisitId: +this.visitId,
      };

      this.$requestAPI.trackPageClick(oTrackData)
        .catch(console.log);
    }
  },
};
</script>

<style lang="scss" scoped>
.title-img {
  max-width: 235px;
  width: 100%;
  height: auto;
}
.rules {
  .rule img {
    height: 44px;
  }
}
.virals {
  max-width: 374px;
  margin: auto;
  margin-left: 1rem;
}

.n-cta ::v-deep {
  @apply mt-4 mr-4;
  svg {
    background-color: #FFD400;
  }
}
</style>
