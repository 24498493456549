<template>
  <ValidationObserver
    v-slot="{ handleSubmit }"
    ref="signUpObserver"
    tag="form"
    @submit.prevent
  >
    <header>
      <h3 class="text-xl font-lato text-white">{{ $t("modals.share_email.title") }}</h3>
      <p class="pb-1 text-xs text-gray-dark">
        {{ $t("modals.share_email.subtitle") }}
      </p>
    </header>
    <div class="modal_body">
      <div v-for="(email, index) of lEmails" :key="index">
        <NText
          :data-cy="'share-email-input-' + (index + 1)"
          :id="'share-email-' + (index + 1)"
          :name="$t('register.form.email')"
          type="email"
          v-model="email.value"
          rules="email"
          labelPosition="side"
          textColor="black"
          @input="emailChanged"
          :backgroundColor="colorStyle.quinary"
          :designType="panoplyStyle.inputs.designType"
          :errorColor="colorStyle.error"
        >
          {{$t('modals.share_email.email', {index: index + 1})}}
        </NText>
        <span class="text-sm text-center text-error" v-show="email.hasError">{{
          $t("modals.share_email.already_used")
        }}</span>
      </div>

      <p class="pb-3">
        <small class="text-xs text-white">
          {{ $t("modals.share_email.mentions") }}
        </small>
      </p>

      <p class="text-sm text-center text-error" v-show="errorMessage !== ''">
        {{ errorMessage }}
      </p>

      <div class="text-center text-white" v-show="emailsSent">
        {{ emailsSent }}
      </div>
      <NCta 
        class="send-button" 
        data-cy="share-mail-submit" 
        type="button" 
        :disabled="isLoading" 
        borderRadius="30px"
        backgroundColor="#FFD400"
        textColor="#014457"
        @click.native="handleSubmit(submit)"
      >
        <span v-show="!isLoading">{{$t('modals.share_email.submit')}}</span>
        <span class="flex" v-show="isLoading">
          <loading-icon
            class="m-auto mx-2 text-white"
            fill="white"
          ></loading-icon>
          <p class="mx-2">{{ $t("loading") }}</p>
        </span>
      </NCta>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import { NText } from "@team-uep/n-inputs";
import NCta from "@team-uep/n-cta";
import LoadingIcon from '@/components/icons/LoadingIcon'
import design from '@/validators/design'

export default {
  name: "DiffusionEmailsModal",

  components: {
    NCta,
    NText,
    LoadingIcon,
  },

  data: () => ({
    colorStyle: design.colors,
    panoplyStyle: design.panoply,
    isLoading: false,
    lEmails: [
      { value: "", hasError: false },
      { value: "", hasError: false },
      { value: "", hasError: false },
    ],
    emailsSent: false,
    errorMessage: "",
  }),

  computed: {
    ...mapGetters({
      userEmail: "user/email",
    }),
  },

  methods: {
    submit: function () {
      const emails = this.emailObjToArray(this.lEmails);
      const message = this.handleErrorMessage(emails);

      if (message) {
        this.sendVirals(emails);
      }
    },
    emailChanged: function () {
      this.errorMessage = "";
    },

    handleErrorMessage: function (emails) {
      if (emails.length === 0) {
        this.errorMessage = this.$t("modals.share_email.error.no_email");

        return false;
      }

      if (new Set(emails).size !== emails.length) {
        this.errorMessage = this.$t("modals.share_email.error.duplicate_email");

        return false;
      }

      if (emails.includes(this.userEmail)) {
        this.errorMessage = this.$t("modals.share_email.error.own_email");

        return false;
      }

      return true;
    },

    emailObjToArray: function (lEmails) {
      return Object.keys(lEmails).reduce((final, emailKey) => {
        if (lEmails[emailKey].value) {
          final.push(lEmails[emailKey].value);
        }

        return final;
      }, []);
    },

    parseApiResult: function (lEmails, apiResult) {
      return Object.keys(apiResult).reduce(
        (final, email) => {
          const status = apiResult[email].code === 200 ? "good" : "bad";

          final[status].push(
            Object.keys(lEmails).find((key) => lEmails[key].value === email)
          );

          return final;
        },
        {
          good: [],
          bad: [],
        }
      );
    },

    sendVirals: function (emails) {
      const that = this;

      this.isLoading = true;

      this.$requestAPI
        .diffusionSendVirals({
          lEmails: emails,
        })
        .then(({ response, data }) => {
          const parsedResult = this.parseApiResult(this.lEmails, data.data[0]);

          // Handle bad emails
          parsedResult.bad.forEach((key) => {
            this.lEmails[key].hasError = true;
          });

          // Handle good emails
          parsedResult.good.forEach((key) => {
            this.lEmails[key].value = "";
            this.lEmails[key].hasError = false;
          });

          // If more than one good email is sent
          if (parsedResult.good.length > 0) {
            this.emailsSent =
              parsedResult.good.length > 1
                ? this.$tc("modals.share_email.success", 2)
                : this.$tc("modals.share_email.success", 1);
          }

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.n-cta ::v-deep {
  width: 215px;
  padding: 8px 25px !important;
  // background-color: #FFD400 !important;
  font-family: "Oswald Bold";
}
.send-button {
  margin: auto;
}
.n-input ::v-deep {
  font-family: "Lato bold";
  .n-input__error-icon {
    display: none;
  }
  .n-input__error-text span {
    line-height: 14px !important;
  }
  .n-input__radio {
    // border-color: #bfbfbf !important;
    border-radius: 5px !important;
    // background-color: #FFFFFF19 !important;
    width: 120px;
    height: 50px;
    span {
      color: white;
      // border-color: white !important;
    }
  }
  span {
    color: white;
  }
  .n-input__popin {
    width: 57% !important;
    height: 56% !important;
    margin-left: 0px;
  }
  .n-input__radio-label {
    font-family: "Lato Bold";
  }
}
small {
  line-height: 15px;
}
</style>
