import cookiesBanner from './html/cookies/banner.html'
import cookiesModalContent from './html/cookies/modal/content.html'
import desaboLink from './html/desabo/link.html'
import registerTitle from './html/register/title.html'
import registerInstruction from './html/register/instruction.html'
import diffusionTitle from './html/diffusion/title.html'
import diffusionSubtitle from './html/diffusion/subtitle.html'
import footerRealisationAddress from './html/footer/realisation/address.html'
import footerFooterTermsModal from './html/footer/footer_terms_modal.html'
import footerFooterChartefbModal from './html/footer/footer_chartefb_modal.html'
import footerFooterRealisationModal1000mercis from './html/footer/footer_realisation_modal_1000mercis.html'
import footerFooterRealisationModalNumberly from './html/footer/footer_realisation_modal_numberly.html'
import footerFooterMentions from './html/footer/footer_mentions.html'
import footerFooterMentions2 from './html/footer/footer_mentions2.html'
import footerMentions from './html/footer/mentions.html'
import modalsShareLinkTitle from './html/modals/share_link/title.html'
import optinisationSubtitle from './html/optinisation/subtitle.html'

export default {
  loading: "Chargement",
  cookies: {
    banner: cookiesBanner,
    modal: {
      content: cookiesModalContent
    }
  },
  desabo: {
    title: "Votre désinscription a bien été prise en compte.",
    button: "Je me réinscris",
    link: desaboLink,
    resub: "Vous êtes bien réinscrit"
  },
  register: {
    title: registerTitle,
    subtitle: "Montrez à votre ado que vous n’êtes <br class='lg:hidden'>pas trop rétro ! <br class='hidden lg:block'>#onsecomprend <br class='lg:hidden'>et tentez de gagner :",
    instruction: registerInstruction,
    submit: "Je participe !",
    already_registered: "Déjà inscrit(e) ?",
    not_registered: "Pas encore inscrit",
    not_mandatory: "Pas obligatoire",
    mandatory: "* Champs obligatoire",
    underage_description: "Hey, malheureusement, <br> il semblerait que tu n'aies pas l'âge requis pour participer mais n'hésite<br> pas à partager le bon plan <br>avec tes parents !",
    form: {
      gender: "Civilité",
      gender_label: "Quelle est votre civilité ? *",
      first_name: "Prénom",
      first_name_label: "Quel est votre prénom ? *",
      last_name: "Nom",
      last_name_label: "Quel est votre nom ? *",
      email: "adresse email",
      email_label: "Quelle est votre adresse email ? *",
      mobile: "Numéro de mobile",
      zipcode: "Code postal",
      city: "Ville",
      country: "Pays",
      birthday: "Date de naissance",
      birthday_label: "Quelle est votre date de naissance ? *",
      iOptin_2_label: "J'accepte de recevoir des offres commerciales, par voie électronique, de la part de Ma French Bank",
      terms: "Je reconnais avoir lu et accepté le règlement de l’opération.*",
      submit: "Valider",
      errors: {
        birthday: {
          invalid: "Votre date de naissance est incorrecte.",
          too_young: "Vous devez avoir plus de {age} ans pour participer."
        },
        email: {
          invalid: "Le champ Email doit être une adresse e‑mail valide.",
          already_used: "L'adresse email renseignée est déjà utilisée."
        }
      }
    },
    relog: {
      description: "Si vous êtes inscrit(e), vous allez recevoir un email pour vous reconnecter.",
      resend: "Je n'ai pas reçu l'email",
      submit: "Envoyer"
    },
    underage: {
      subject: "Bonjour,"
    }
  },
  quiz: {
    quiz1: {
      value: 1,
      question_id: "iQuestion_101",
      numerotation: "1.svg",
      instruction: "Que signifie :",
      question: "« Je suis en galère de biff pour un date avec mon crush ? »",
      answers: [
        {
          value: 1,
          image: "pizza@2x.png",
          letter_image: "A@2x.png",
          description: "« Je n’ai pas assez d’argent pour organiser une soirée avec mon pote. »"
        },
        {
          value: 2,
          image: "coeur@2x@2x.png",
          letter_image: "B@2x.png",
          description: "« Je n’ai plus beaucoup d’argent pour mon rendez-vous galant. »"
        },
        {
          value: 3,
          image: "voiture.png",
          letter_image: "C@2x.png",
          description: "« Ma galère m'a laché et je n'ai pas d'argent pour la dépanneuse. »"
        },
      ]
    },
    quiz2: {
      value: 2,
      question_id: "iQuestion_201",
      numerotation: "2@2x.png",
      instruction: "",
      question: "Laquelle de ces expressions n’a aucun rapport avec l’argent ?",
      answers: [
        {
          value: 1,
          image: "cochon@2x.png",
          letter_image: "A@2x.png",
          description: "« C’est la hess. »"
        },
        {
          value: 2,
          image: "portemonaie@2x.png",
          letter_image: "B@2x.png",
          description: "« Je suis en dèche. »"
        },
        {
          value: 3,
          image: "liasse@2x.png",
          letter_image: "C@2x.png",
          description: "« J'ai plus de bigo. »"
        },
      ]
    },
    quiz3: {
      value: 3,
      question_id: "iQuestion_301",
      numerotation: "3@2x.png",
      instruction: "",
      question: "C’est combien pour se taper un blockbuster avec les sangs ?",
      answers: [
        {
          value: 1,
          image: "chien@2x.png",
          letter_image: "A@2x.png",
          description: "1 par personne"
        },
        {
          value: 2,
          image: "pieces@2x.png",
          letter_image: "B@2x.png",
          description: "10 €"
        },
        {
          value: 3,
          image: "calendrier@2x.png",
          letter_image: "C@2x.png",
          description: "1 fois par semaine"
        },
      ]
    },

    quiz4: {
      value: 4,
      question_id: "iQuestion_401",
      numerotation: "4@2x.png",
      instruction: "",
      question: "Qu'est-ce qu'un Tiktoker?",
      answers: [
        {
          value: 1,
          image: "parchemin@2x.png",
          letter_image: "A@2x.png",
          description: "Un ménestrel des temps modernes"
        },
        {
          value: 2,
          image: "coeur@2x.png",
          letter_image: "B@2x.png",
          description: "Un nouveau genre d'influenceurs"
        },
        {
          value: 3,
          image: "horloge@2x.png",
          letter_image: "C@2x.png",
          description: "Le nom de la dernière horloge connectée"
        },
      ]
    },

    quiz5: {
      value: 5,
      question_id: "iQuestion_501",
      numerotation: "5@2x.png",
      instruction: "Que signifie :",
      question: "« Askip il fait le djo, mais il a pas les lovés pour mettre bien <span class='whitespace-nowrap'>sa gow. »</span>",
      answers: [
        {
          value: 1,
          image: "vinyle@2x.png",
          letter_image: "A@2x.png",
          description: "« D’après ce qu’il dit, il est DJ mais il n’a pas les qualités pour plaire au public. »"
        },
        {
          value: 2,
          image: "yeah@2x.png",
          letter_image: "B@2x.png",
          description: "« Il fait le show mais il ne se donne pas les moyens pour prendre sa vie en main. »"
        },
        {
          value: 3,
          image: "diamant@2x.png",
          letter_image: "C@2x.png",
          description: "« Il parait qu'il joue les caïds mais il n'a pas l'argent pour faire plaisir à sa copine. »"
        },
      ]
    },
    instruction: "questions",
    rules: [
      {
        value: 0,
        image: "../../assets/quiz/y1@2x.png",
        description: "réponse possible par question"
      },
      {
        value: 1,
        image: "../../assets/quiz/y1@2x.png",
        description: "point par bonne réponse"
      },
      {
        value: 2,
        image: "../../assets/quiz/y1@2x.png",
        description: "point = 1 chance au tirage au sort"
      },
    ],
    submit: "Je valide !",
    rules_submit: "C'est compris !"
  },
  diffusion: {
    title: diffusionTitle,
    subtitle: diffusionSubtitle,
    redirection: "Découvrir nos offres",
    challenge: "Challengez vos proches en partageant votre âge et augmentez vos chances de gagner 2 smartphones dernière génération.",
    find: "Avec le compte WeStart,<br> vous gardez un oeil sur ses dépenses et lui accordez un peu de liberté !",
    submit: "Je découvre !",
    scores: [
      {
        score: "0",
        image: "0-5@2x.png",
        description: "D’après votre score, vous avez <span class='font-latoBold'>65 ans !</span></br> euh … avez-vous bien compris le jeu ? Ne vous découragez pas !"
      },
      {
        score: "1",
        image: "1-5@2x.png",
        description: "D’après votre score, vous avez <span class='font-latoBold'>55 ans !</span> L’honneur est sauf mais les jeunes risquent de vous prendre pour un dinosaure !"
      },
      {
        score: "2",
        image: "2-5@2x.png",
        description: "D’après votre score, vous avez <span class='font-latoBold'>45 ans !</span></br> Un traducteur ne serait pas de refus pour comprendre votre ado."
      },
      {
        score: "3",
        image: "3-5@2x.png",
        description: "D’après votre score, vous avez <span class='font-latoBold'>35 ans !</span></br> En regardant quelques clips avec votre ado vous devriez pouvoir améliorer ce score !"
      },
      {
        score: "4",
        image: "4-5@2x.png",
        description: "D’après votre score, vous avez <span class='font-latoBold'>27 ans !</span></br> Vous vous défendez très bien, bravo ! Votre ado peut être fier de vous."
      },
      {
        score: "5",
        image: "5-5@2x.png",
        description: "D’après votre score, vous avez <span class='font-latoBold'>20 ans</span> !</br> Vous êtes bilingue ado, une qualité à mettre sur le CV !"
      },
    ],
    share: {
      email: "Email",
      facebook: "Facebook",
      twitter: "Twitter",
      twitter_text: "twitter text",
      whatsapp: "Whatsapp",
      whatsapp_text: "",
      whatsapp_text_s0: "Rejoins le #WeStartChallenge et montre-moi que tu es bilingue ado ! A gagner : 2 smartphones dernière génération et pleins d'autres cadeaux à partager.",
      whatsapp_text_s1: "Rejoins le #WeStartChallenge et montre-moi que tu es bilingue ado ! A gagner : 2 smartphones dernière génération et pleins d'autres cadeaux à partager.",
      whatsapp_text_s2: "Rejoins le #WeStartChallenge et montre-moi que tu es bilingue ado ! A gagner : 2 smartphones dernière génération et pleins d'autres cadeaux à partager.",
      whatsapp_text_s3: "Rejoins le #WeStartChallenge et montre-moi que tu es bilingue ado ! A gagner : 2 smartphones dernière génération et pleins d'autres cadeaux à partager.",
      whatsapp_text_s4: "Rejoins le #WeStartChallenge et montre-moi que tu es bilingue ado ! A gagner : 2 smartphones dernière génération et pleins d'autres cadeaux à partager.",
      whatsapp_text_s5: "Rejoins le #WeStartChallenge et montre-moi que tu es bilingue ado ! A gagner : 2 smartphones dernière génération et pleins d'autres cadeaux à partager.",

      facebook_text_s0: "Comprends+-+tu+l'ado+mieux+que+moi+?",
      facebook_text_s1: "Comprends+-+tu+l'ado+mieux+que+moi+?",
      facebook_text_s2: "Comprends+-+tu+l'ado+mieux+que+moi+?",
      facebook_text_s3: "Comprends+-+tu+l'ado+mieux+que+moi+?",
      facebook_text_s4: "Réussiras+-+tu+à+me+battre+?",
      facebook_text_s5: "Es+-+tu+aussi+bon+que+moi+?",
      messenger: "Messenger",
      link: "Lien",
      summary: "voir mes amis inscrits"
    }
  },
  end: {
    title: "Merci pour votre participation !",
    description: "Notre grand jeu #WeStartChallenge <br> est désormais terminé. <br>Les gagnants seront tirés au sort <br>lundi 18 janvier 2021. Gardez un oeil sur votre boîte email, nous croisons les<br> doigts pour vous 🤞",
    subtitle: "L’opération XXXX est désormais terminée. Le gagnant du tirage au sort sera contacté prochainement par email.",
    submit: "Je découvre !"
  },
  footer: {
    terms: "Règlement*",
    mentions: footerMentions, 
    mentionsModal: {
      button: "Mentions légales",
      content: footerMentions
    },
    contact: {
      button: "Nous contacter",
      email: "contact@do-you-speak-ado.com",
      subject: "Ma French Bank - Do you speak ado ?"
    },
    discover: {
      button: "Découvrez le compte WeStart",
    },
    charteFb: "Charte de confidentialité",
    realisation: {
      button: "Réalisation",
      address: footerRealisationAddress,
      email: "Email",
      contact: "N'hésitez pas à nous contacter",
      mailto: "juliette@numberly.com",
      numberly: "http://www.numberly.com/fr"
    },
    footer_terms_btnText: "Règlement",
    footer_terms_modal: footerFooterTermsModal,
    footer_contact_btnText: "Contact",
    footer_contact_email: "contact@lequizduchef-bbq-XXXXXXX.fr",
    footer_contact_subject: "Ma French Bank - Do you speak ado ?",
    footer_chartefb_client: "XXXXXXX",
    footer_chartefb_btnText: "Charte de confidentialité",
    footer_chartefb_modal: footerFooterChartefbModal,
    footer_realisation_btnText: "Réalisation",
    footer_realisation_modal_1000mercis: footerFooterRealisationModal1000mercis,
    footer_realisation_modal_numberly: footerFooterRealisationModalNumberly,
    footer_mentions: footerFooterMentions,
    footer_mentions2: footerFooterMentions2
  },
  modals: {
    share_link: {
      title: modalsShareLinkTitle,
      subtitle: "en copiant l'URL",
      action: "COPIER",
      success: "Le lien a bien été copié"
    },
    summary: {
      title: "Vos amis invités",
      relance: "Relancer",
      registered: "Inscrit"
    },
    share_email: {
      title: "Invitez vos amis",
      email: "Email {index}",
      subtitle: "via leur adresse email",
      already_used: "Cette personne a déjà participée au jeu.",
      mentions: "Les adresses email que vous indiquez ici nous permettront de transmettre à vos amis, vos invitations à venir jouer à ce jeu. Ces adresses ne seront ni conservées, ni réutilisées à l’issue du jeu.",
      submit: "J'envoie mes invitations",
      error: {
        no_email: "Veuilllez saisir au moins un email",
        duplicate_email: "Au moins 2 des emails que vous avez saisis sont identiques.",
        own_email: "Vous ne pouvez pas saisir votre propre email."
      },
      success: "L'email a bien été envoyé.|Les emails ont bien été envoyés."
    }
  },
  optinisation: {
    title: "Merci!",
    subtitle: optinisationSubtitle
  }
}
