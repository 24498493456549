<template>
  <div> 
    <div class="mentionsModal" v-html="$t('footer.mentionsModal.content')"></div>
  </div>
</template>

<script>
export default {
  name: 'MentionsModal',
  mounted() {
    this.$emit('updateSize', 'max')
  },
}
</script>

<style lang="scss" scoped>
p {
  word-break: break-word;
}

.mentionsModal /deep/ {
  p {
    margin: 10px 0;
    font-size: 14px;
  }

  a {
    color: #3498db;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  h1 {
    font-size: 36px;
  }
  
  h2 {
    font-size: 22px;
  }
}
</style>
