export default {
  state: {
    modalConfig: {
      isOpen: false,
      modalComponent: null,
      param: null
    },
  },

  actions: {
    updateModalConfig({ commit }, modalConfig) {
      commit('UPDATE_MODAL_CONFIG', modalConfig);
    },
  },

  mutations: {
    UPDATE_MODAL_CONFIG(state, modalConfig) {
      state.modalConfig = modalConfig;
    },
  },

  getters: {
    modalConfig: (state) => state.modalConfig,
  },
};
