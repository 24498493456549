<template>
  <NModal 
    id="modal-wrapper" 
    ref="modal" 
    @close="()=> {$emit('close')}" 
    :size="size" 
    :visible="modalConfig.isOpen" 
    :class="modalConfig.modalClass || ''"
  >
    <component data-cy="modal-content" @updateSize="updateSize" :id="modalConfig.modalComponent" :ref="childrenRef" :is="modalConfig.modalComponent" :param="modalConfig.param" />
  </NModal>
</template>

<script>
import CookiesModal from './CookiesModal.vue'
import TermsModal from './TermsModal.vue'
import CharteFbModal from './CharteFbModal.vue'
import RealisationModal from './RealisationModal.vue'
import MentionsModal from './MentionsModal.vue'
import DiffusionEmailsModal from './DiffusionEmailsModal.vue'
import ShareLinkModal from './ShareLinkModal.vue'
import SummaryModal from './SummaryModal.vue'
import WinnersModal from './WinnersModal.vue'

import QuizRulesModal from './QuizRulesModal.vue'
import UnderageModal from './UnderageModal.vue'

export default {
  name: 'Modal',
  props: ['modalConfig'],
  data() {
    return {
      size : 'min'
    }
  },
  components: {
    CookiesModal,
    TermsModal,
    CharteFbModal,
    RealisationModal,
    MentionsModal,
    DiffusionEmailsModal,
    ShareLinkModal,
    SummaryModal,
    WinnersModal,
    QuizRulesModal,
    UnderageModal
  },
  computed :{
    childrenRef () {
      return 'contentModal' + '_' + ( this.modalConfig.modalComponent || '' )
    }
  },
  mounted() {
    this.updateSize()
  },
  methods: {
    updateSize (newSize) {
      if ( newSize ) {
        this.size = newSize
      } else {
        this.size = 'min'
      }
    }
  },
  watch: {
    'modalConfig.isOpen' (newVal) {
      this.updateSize()
    }
  }
}
</script>

<style lang="scss" scoped>
#modal-wrapper ::v-deep {
  .n-modal{
    border-radius: 10px !important;
  }
}
.diffusion-email-modal ::v-deep {
  .n-modal {
    background-color: #0D4355 !important;
    border: 2px solid #FFFFFF;
    svg {
      fill: white;
    }
  }
}
.quiz-rules-modal ::v-deep, 
.under-age-modal ::v-deep {
  .n-modal {
    background-color: #06CCC6 !important;
    border: 2px solid #FFFFFF;
    height: 415px;
  }
}
.under-age-modal ::v-deep {
  .n-modal {
    height: 315px !important;
  }
}
</style>
