import { EventTarget } from "event-target-shim/dist/event-target-shim.umd";
import 'custom-event-polyfill';
import Hooks from "./Hooks";
import ApiError from "./ApiError";

const STATUS_ERRORS = {
  403: 'unauthorized',
  404: 'not-found',
  409: 'already-registered',
  419: 'token-expired',
  503: 'service-unavailable',
  default: 'server-error'
}

/**
 * @typedef {Object} APIResponse
 * @property {Response} response
 * @property {Object} data
 */

/**
 * Request API class, extends `EventTarget` so you can listen specific events.
 */
export class RequestAPI extends EventTarget {
  /**
   * @param {string} baseURL base URL determined from active decli.
   */
  constructor(baseURL) {
    super();

    /** @type {string} */
    this.baseURL = baseURL;

   /** @type {string|null} */
    this.token = null;

    /**
     * Default options for any HTTP request executed.
     */
    this.defaultOptions = {
      cache: 'no-cache',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    this._hooks = new Hooks([
      'send-relog-email',
      'relogged',
      'registered-or-relogged',
      'email-suggestion',
      'dedup-email-checked',
      'send-captcha2-token',
      'qualif-register-sent',
      'tracked-page-click',
      'sent-virals',
      'summary-viral',
      'token-expired',
      'token-regenerated',
      'session-current-from-auto-register',
      'session-current',
      'registered'
    ])

    this._retries = new Hooks([
      ...Object.values(STATUS_ERRORS),
      'offline'
    ])
  }

  /**
   * @param {string} endpoint
   * @param {RequestInit} options
   * @return {Promise<APIResponse>}
   */
  async _requestWithoutToken(endpoint, options = {}) {
    options = {
      ...options,
      ...this.defaultOptions,
    };

    // If token undefined, it will not be setted in the URL.
    const url = this._setURLParameters(
      `${this.baseURL}/${endpoint}`,
      {
        token: this.token,
        iefix: new Date().getTime(),
      },
    );

    let response = null;

    // Fetch promises only reject with a TypeError when a network error occurs.
    // This try-catch block will handle network errors and avoid a useless
    // Sentry log.
    try {
      response = await fetch(url, options);
    } catch(err) {
      if (navigator && !navigator.onLine) {

        // Try to resolve request with the hook
        if (this._retries.has('offline')) {
          await this._retries.dispatch('offline', response)
          
          // Retry request, hook can not do recursive calls
          this._retries.lock('offline')
          const newRequestWithoutTokenCall = await this._requestWithoutToken(endpoint, options)
          this._retries.unlock('offline')

          return newRequestWithoutTokenCall
        }

        this.dispatchEvent(new CustomEvent('offline'));
        throw new ApiError(response, 'Offline')
      }

      throw err
    }

    // Bad status
    if (!response.ok) {

      // Try to resolve request with the hook
      const retryLabel = STATUS_ERRORS[response.status] || STATUS_ERRORS.default
      if (this._retries.has(retryLabel)) {
        await this._retries.dispatch(retryLabel, response)
        
        // Retry request, hook can not do recursive calls
        this._retries.lock(retryLabel)
        const newRequestWithoutTokenCall = await this._requestWithoutToken(endpoint, options)
        this._retries.unlock(retryLabel)

        return newRequestWithoutTokenCall
      }

      // Dispatch event throw error
      this._dispatchStatusError(response)
      throw new ApiError(response, `API response status code: ${response.status}`)
    }

    // Check and get response data
    const data = await this._getResponseData(response);
    return { response, data };
  }

  /**
   * If there is no token, cache the original request on the
   * `token-regenerated` event-listener and dispatch the `token-expired`
   * event. This will notify listeners to regenerate a token.
   *
   * Once the token is regenerated, a `token-regenerated` event will be
   * fired, the original request will continue.
   *
   * @param {string} endpoint URL endpoint without base URL.
   * @param {RequestInit} options `fetch` request init options.
   * @return {Promise<APIResponse>}
   */
  async _requestHandler(endpoint, options = {}) {

    // Call the hook
    await this._hooks.dispatch('token-expired', endpoint, options)

    // No token, ask store to regenerate one.
    if (this.token === null) {
      return new Promise((resolve, reject) => {
        // Cache the original request.
        const request = () => {
          this.removeEventListener('token-regenerated', request);

          resolve(
            this._requestWithoutToken(endpoint, {
              ...options,
            }),
          );
        };

        // Execute the original request on `token-regenerated` event.
        this.addEventListener('token-regenerated', request);

        // Notify listeners to regenerate a token.
        this.dispatchEvent(new CustomEvent('token-expired'));
      });
    }

    return this._requestWithoutToken(endpoint, {
      ...options,
    });
  }

  /**
   * @param {string} endpoint URL endpoint without base URL.
   * @param {RequestInit} options `fetch` request init options.
   * @return {Promise<APIResponse>}
   */
  _get(endpoint, options = {}) {
    return this._requestHandler(endpoint, {
      ...options,

      method: 'GET',
    });
  }

  /**
   * @param {string} endpoint URL endpoint without base URL.
   * @param {Object} data data to stringify and send with the request.
   * @param {RequestInit} options `fetch` request init options.
   * @return {Promise<APIResponse>}
   */
  _post(endpoint, data, options = {}) {
    let payload = '';

    try {
      payload = JSON.stringify(data);
    } catch (err) {
      throw new Error('Unable to stringify payload for post request');
    }

    return this._requestHandler(endpoint, {
      ...options,

      method: 'POST',
      body: payload,
    });
  }

  /**
   * Add parameters on the URL with existing parameters included in the URL.
   *
   * @param {string} url
   * @param {Object} [params]
   */
  _setURLParameters(url, params = {}) {
    const parameters = {
      ...params,
    };

    const urlWithoutParams = url.indexOf('?') === -1 ? url : url.substr(0, url.indexOf('?'));

    const hashes = url.indexOf('?') === -1 ? [] : url.slice(url.indexOf('?') + 1).split('&');

    let parametersURL = '';

    // Decode existing parameters from the URL and add them to the parameters
    // object.
    hashes.forEach((hash) => {
      const [key, value] = hash.split('=');

      if (value) {
        parameters[key] = decodeURIComponent(value);
      }
    });

    Object.entries(parameters)
      .filter(([, value]) => value)
      .forEach(([key, value], i) => {
        parametersURL += i === 0 ? `?${key}=${value}` : `&${key}=${value}`;
      });

    return `${urlWithoutParams}${parametersURL}`;
  }

  /**
   * @param {Response} response
   */
  async _dispatchStatusError(response) {

    // Send a custom event for some specifics status code
    // or send the generic error "server-error"
    const errorName = STATUS_ERRORS[response.status] || STATUS_ERRORS.default
    this.dispatchEvent(new CustomEvent(errorName, {
      detail: {
        response
      }
    }));
  }

  /**
   * @param {Response} response
   * @returns {Promise<Object>} payload
   */
  async _getResponseData(response) {

    // Can only fetch JSON
    try {
      const payload = await response.json();
      return payload
    } catch (error) {
      throw new ApiError(response, 'Not a valid JSON response')
    }
  }

  /**
   * Add a hook to a label
   * 
   * @param {String} label        Label of the hook
   * @param {Function} callback   Function called when hook dispatched
   */
  addHook(label, callback) {
    return this._hooks.add(label, callback)
  }

  /**
   * Remove a hook
   * 
   * @param {String} label        Label of the hook
   * @param {Function} callback   Function called when hook dispatched
   */
  removeHook(label, callback) {
    return this._hooks.remove(label, callback)
  }

  /**
   * Add a retry callback to hook and retry fetch after API error
   * 
   * @param {String} label        Label of the hook
   * @param {Function} callback   Function called when hook dispatched
   */
  addRetry(label, callback) {
    return this._retries.add(label, callback)
  }

  /**
   * Remove a retry callback
   * 
   * @param {String} label        Label of the hook
   * @param {Function} callback   Function called when hook dispatched
   */
  removeRetry(label, callback) {
    return this._retries.remove(label, callback)
  }

  /**
   * @param {String} token
   */
  setSessionToken(token = null) {
    this.token = token
  }

  /**
   * @param {Object} payload
   */
  getSessionToken(payload) {
    return this._requestWithoutToken('session/token', {
      method: 'POST',
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.data.data[0] && response.data.data[0]._id) {
          this.setSessionToken(response.data.data[0]._id);

          return response;
        } else {
          throw new ApiError(response, 'Unable to retrieve token (_id) from /session/token');
        }
      })
      .then(async (response) => {

        // Call the hook
        await this._hooks.dispatch('token-regenerated', response)

        this.dispatchEvent(
          new CustomEvent('token-regenerated', {
            detail: response.data,
          }),
        );

        return response;
      });
  }

  /**
   * @param {boolean} calledFromAutoRegister set to true if called from
   *  `registerAuto.js` routing module. Will emit a different `CustomEvent`.
   * @return {Promise<APIResponse>}
   */
  getSessionCurrent(calledFromAutoRegister = false) {
    return this._get('session/current')
      .then(async ({ response, data }) => {
        if (calledFromAutoRegister) {

          // Call the hook
          await this._hooks.dispatch('session-current-from-auto-register', { response, data })

          this.dispatchEvent(
            new CustomEvent('session-current-from-auto-register', {
              detail: data,
            }),
          );
        } else {

          // Call the hook
          await this._hooks.dispatch('session-current', { response, data })

          this.dispatchEvent(
            new CustomEvent('session-current', {
              detail: data,
            }),
          );
        }

        return { response, data };
      });
  }

  /**
   * @param {number} pageID
   * @return {Promise<APIResponse>}
   */
  pageView(pageID) {
    return this._post('track/page_view', {
      iPageId: pageID,
    });
  }

  /**
   * @param {Object} data register data
   * @return {Promise<APIResponse>}
   */
  register(data = {}) {
    return this._post('inscription/register', data)
      .then(async (response) => {

        // Call the hook
        await this._hooks.dispatch('registered', response)

        this.dispatchEvent(
          new CustomEvent('registered', {
            detail: response.data,
          }),
        );

        return response;
      });
  }

  /**
   * @param {string} UID
   * @return {Promise<APIResponse>}
   */
  relog(UID) {
    return this._requestWithoutToken(`session/relog/${UID}`, {
      method: 'GET'
    })
      .then(async (response) => {

        // Call the hook
        await this._hooks.dispatch('relogged', response)

        this.dispatchEvent(
          new CustomEvent('relogged', {
            detail: response.data,
          }),
        );

        return response;
      });
  }

  /**
   * @param {Object} data register/relog data
   * @return {Promise<APIResponse>}
   */
  registerOrRelog(data = {}) {
    return this._post('inscription/register_or_relog', data)
      .then(async (response) => {

        // Call the hook
        await this._hooks.dispatch('registered-or-relogged', response)

        this.dispatchEvent(
          new CustomEvent('registered-or-relogged', {
            detail: response.data,
          }),
        );

        return response;
      });
  }

  /**
   * @param {Object} data relog email
   * @return {Promise<APIResponse>}
   */
  sendRelogEmail(data = {}) {
    return this._post('session/send_relog_email', data)
      .then(async (response) => {

        // Call the hook
        await this._hooks.dispatch('send-relog-email', response)

        this.dispatchEvent(
          new CustomEvent('send-relog-email', {
            detail: response.data,
          }),
        );

        return response;
      });
  }

  /**
   * @param {string} email email from input
   * @return {Promise<APIResponse>}
   */
  suggestion(email = '') {
    return this._get(`inscription/suggestion/${email}`)
      .then(async (response) => {

        // Call the hook
        await this._hooks.dispatch('email-suggestion', response)

        this.dispatchEvent(
          new CustomEvent('email-suggestion', {
            detail: response.data,
          }),
        );

        return response;
      });
  }

  /**
   * @param {string} email email from input
   * @return {Promise<APIResponse>}
   */
  dedupEmail(email = '') {
    return this._get(`inscription/dedup/${email}`)
      .then(async (response) => {

        // Call the hook
        await this._hooks.dispatch('dedup-email-checked', response)

        this.dispatchEvent(
          new CustomEvent('dedup-email-checked', {
            detail: response.data,
          }),
        );

        return response;
      });
  }

  /**
   * @param {Object} data Google re-captcha2 data for back-end
   * @return {Promise<APIResponse>}
   */
  sendCaptchaToken(data = {}) {
    return this._post('session/captcha2_new_check', data)
      .then(async (response) => {

        // Call the hook
        await this._hooks.dispatch('send-captcha2-token', response)

        this.dispatchEvent(
          new CustomEvent('send-captcha2-token', {
            detail: response.data,
          }),
        );

        return response;
      });
  }

  /**
   * @param {Object} data qualif data with `oResponses`
   * @return {Promise<APIResponse>}
   */
  qualifRegister(data = {}) {
    return this._post('qualif/register', data)
      .then(async (response) => {

        // Call the hook
        await this._hooks.dispatch('qualif-register-sent', response)

        this.dispatchEvent(
          new CustomEvent('qualif-register-sent', {
            detail: response.data,
          }),
        );

        return response;
      });
  }

  /**
   * @param {Object} data page tracking data
   * @return {Promise<APIResponse>}
   */
  trackPageClick(data = {}) {
    return this._post('track/page_click', data)
      .then(async (response) => {

        // Call the hook
        await this._hooks.dispatch('tracked-page-click', response)

        this.dispatchEvent(
          new CustomEvent('tracked-page-click', {
            detail: response.data,
          }),
        );

        return response;
      });
  }

  /**
   * @return {Promise<APIResponse>}
   */
  getSummaryViral() {
    return this._get("diffusion/summary_viral") 
  }

  /**
   * @param {Object} data viral emails data
   * @return {Promise<APIResponse>}
   */
  diffusionSendVirals(data = {}) {
    return this._post('diffusion/send_virals', data)
      .then(async (response) => {

        // Call the hook
        await this._hooks.dispatch('sent-virals', response)

        this.dispatchEvent(
          new CustomEvent('sent-virals', {
            detail: response.data,
          }),
        );

        return response;
      });
  }

  /**
   * @return {Promise<APIResponse>}
   */
  diffusionSummaryViral() {
    return this._get('diffusion/summary_viral')
      .then(async (response) => {

        // Call the hook
        await this._hooks.dispatch('summary-viral', response)

        this.dispatchEvent(
          new CustomEvent('summary-viral', {
            detail: response.data,
          }),
        );

        return response;
      });
  }
} 
