<template>
  <div class="error-page font-lato text-white">
    <strong>This site is temporarily unavailable.</strong>
    We apologize for the inconvenience and ask that you please try back again later.
    <div class="separator">______________</div>
    <strong>Ce site est momentanément indisponible.</strong>
    Nous vous prions de nous excuser pour la gêne occasionnée. Nos services seront à nouveau disponibles dans quelques instants.
  </div>
</template>

<script>
export default {
  name: 'ErrorPage'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.error-page {
  width: 100vw;
  height: 100vh;    
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.separator {
  margin: 1rem auto 2rem auto;
}
</style>
