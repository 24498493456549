<template>
  <div class="text-center">
    <img
      class="title-img m-auto -mt-4 ml-4"
      src="../../assets/quiz/titre_pop_comment@2x.png"
      alt
      srcset
    />
    <div class="rules mt-4">
      <div 
        class="rule flex"
      >
        <img
          src="../../assets/quiz/y5@2x.png"
          alt
          srcset
        />
        <p class="mt-1 text-left font-latoBold" v-html="$t(`quiz.instruction`)"></p>
      </div>

      <div 
        class="rule flex"
        v-for="rule in $t(`quiz.rules`)"
        :key="rule.value"
      >
        <img
          src="../../assets/quiz/y1@2x.png"
          alt
          srcset
        />
        <p class="mt-1 text-left font-latoBold" v-html="$t(`quiz.rules[${rule.value}].description`)"></p>
      </div>
    </div>

    <span class="table m-auto mt-4">
      <NCta
        borderRadius="30px"
        backgroundColor="#0D4355"
        textColor="white"
        data-cy="submit-register"
        @click.native="closeModal()"
        designType="round"
        ref="form_submit_button"
      >
      <span class="text-md font-oswaldBold uppercase">{{$t('quiz.rules_submit')}}</span></NCta>
    </span>
    
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'QuizRulesModal',

  data: function() {
    return {
    };
  },

  methods: {
    ...mapActions(['updateModalConfig']),
    closeModal() {
      this.updateModalConfig({ isOpen: false, modalComponent: '' })
    }
  },
};
</script>

<style lang="scss" scoped>
.title-img {
  max-width: 188px;
  width: 100%;
  height: auto;
}
.rules {
  color: #0D4355;

  .rule img {
    height: 44px;
  }
}
.n-cta ::v-deep {
  width: 200px;
  background-color: #0D4355 !important;
  font-family: "Oswald Bold";
}
</style>
